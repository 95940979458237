import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INTERCEPT } from '@tokens/http-itercept.token';
import { Observable } from 'rxjs';

// TODO check for usage
@Injectable()
export class TransformBodyInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(INTERCEPT)) {
      const { method } = req;
      if (method === 'POST' || method === 'PUT') {
        const { body } = req;

        if (body) {
          return next.handle(req.clone({ body: this.transformBody(body) }));
        }
      }
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }

  private transformBody(body: any): typeof body {
    const transformedBody: any = {};
    for (let key in body) {
      const value = body[key];
      if (value && typeof value === 'object' && value['id']) {
        const keyWithId = key + '_id';
        transformedBody[keyWithId] = value['id'];
      } else if (value && typeof value === 'object' && !Array.isArray(value)) {
        transformedBody[key] = this.transformBody(value);
      } else {
        transformedBody[key] = value;
      }
    }

    return transformedBody;
  }
}
