<div class="header">
  {{ 'main_menu.' + menuItem.name | translate | uppercase }}
</div>
@for (item of menuItem.childUrls; track item) {
  <lf-link
    class="submenu-item lf-link"
    [link]="[item.url]"
    >{{ 'main_menu.sub_menu.' + item.name | translate }}</lf-link
  >
}
