<div class="page-tab">
  <abm-icon src="assets/icons/cluster/icon-list.svg"
            [height]="18"
            [width]="18"></abm-icon>

  <p class="page-tab-content">{{ name }}</p>
</div>

<div class="divider"></div>

<div class="shadow"
     [title]="name"></div>
