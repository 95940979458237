import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  tokenService = inject(TokenService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();

    const headers = req.headers
      .set('Content-Type', req.headers.get('Content-Type') || 'application/json')
      .set('Accept', req.headers.get('Accept') || 'application/json')
      .set('authorization', token || '');

    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
