import { Pipe, PipeTransform } from '@angular/core';
import { PermissionPipe } from '@permissions/utils';
import { UserPermissions } from '@type/user.type';

@Pipe({
  name: 'insightsPermissionFull',
  pure: false,
  standalone: true,
})
export class InsightsPermissionFullPipe extends PermissionPipe implements PipeTransform {
  permissionKey: keyof UserPermissions = 'insightsFull';
}
