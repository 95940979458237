import { IconComponent } from '@abm/icon';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTabComponent } from '../base-tab/base-tab.component';

@Component({
  selector: 'app-pinned-tab',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './pinned-tab.component.html',
  styleUrls: ['./pinned-tab.component.scss', './../base-tab/base-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinnedTabComponent extends BaseTabComponent {
  clickHandler(event: Event): void {
    this.category.set(this.data);
  }
}
