<div class="header-insights"
     (clickOutside)="clickOutside()"
     [trigger]="overlayTrigger">
  <h4 class="insight-title">{{ 'insights.title' | translate }}</h4>

  @if (!!newInsights().length) {

  <div class="subtitle new-insights">
    <p>{{ 'insights.type.new' | translate }}</p>
    @if (null | insightsPermissionFull) {
    <p class="btn"
       (click)="$event.stopImmediatePropagation(); readAll()">
      <abm-icon [src]="'assets/icons/icon-read.svg'"
                [width]="20"
                [height]="20" />
      <span>{{ 'insights.read_all' | translate }}</span>
    </p>
    }
  </div>
  <div class="insights-content">
    @for (item of newInsights(); track item.id) {
    <app-header-insight-item (insightChange)="changeInsightReadState($event)"
                             [insight]="item"></app-header-insight-item>
    }
  </div>

  }

  @if (!!recentInsights().length) {

  <div class="subtitle">{{ 'insights.type.recent' | translate }}</div>
  <div class="insights-content">
    @for (item of recentInsights(); track item.id) {
    <app-header-insight-item [insight]="item"
                             (insightChange)="changeInsightReadState($event)" />
    }
  </div>

  }

  @if (emptyState()) {

  <div class="empty-state">
    <abm-icon [src]="'assets/images/insight-empty-state.svg'"
              [width]="148"
              [height]="148"></abm-icon>

    <p class="subtitle">{{ 'insights.empty_state' | translate }}</p>
  </div>
  }

</div>
