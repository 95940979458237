import { WritableSignal, signal } from '@angular/core';
import { CategoryType } from '@type/categories.type';

export interface PageTab<T = any> {
  isActive: WritableSignal<boolean>;
  isPinned: boolean;
  name: string;
  data: T;
}

export class CategoryPageTab implements PageTab<CategoryType> {
  isActive: WritableSignal<boolean> = signal(false);
  isPinned: boolean = false;
  name: string;
  data: CategoryType;

  constructor(category: CategoryType, isPinned: boolean = false) {
    this.name = category.name;
    this.data = category;
    this.isPinned = isPinned;
  }

  static create(category: CategoryType, isPinned: boolean = false) {
    return new CategoryPageTab(category, isPinned);
  }
}
