import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { API_URL } from '@tokens/api-url.token';
import { joinUrls } from '@utils/urls';
import { Observable } from 'rxjs';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private apiUrl = inject(API_URL);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = req.clone();

    if (url.includes('assets/')) return next.handle(req);

    if (Object.keys(environment.urls).some((key) => url.includes(key))) {
      return next.handle(req.clone({ url: joinUrls(this.apiUrl, url) }));
    }
    return next.handle(req);
  }
}
