import { IconComponent } from '@abm/icon';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTabComponent } from '../base-tab/base-tab.component';

@Component({
  selector: 'app-add-tab',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './add-tab.component.html',
  styleUrls: ['./add-tab.component.scss', './../base-tab/base-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTabComponent extends BaseTabComponent {
  clickHandler(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
