<div class="menu-top">
  @for (item of topMenu | menuPermissions; track item) {
    <app-menu-item [menuItem]="item" />
  }
</div>

<div class="menu-bottom">
  @for (item of bottomMenu | menuPermissions; track item) {
    <app-menu-item [menuItem]="item" />
  }
</div>
