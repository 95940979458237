import { MenuModule } from '@abm/menu';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CategoryPageTab, PageTab } from '@modules/tabs/interfaces/tab.interface';
import { isOwnCategory } from '@permissions/utils';
import { CategoryService } from '@services/category/category.service';
import { DBService, NotFoundRecordError } from '@services/d-b/d-b.service';
import { CATEGORY } from '@tokens/category.token';
import { USER } from '@tokens/user.token';
import { CategoryId, CategoryType } from '@type/categories.type';
import { animationFrameScheduler, fromEvent, tap, throttleTime } from 'rxjs';
import { translateTabListByIndex } from '../../utils/translate-tab-list.util';
import { AddTabComponent } from '../add-tab/add-tab.component';
import { BaseTabComponent } from '../base-tab/base-tab.component';
import { PinnedTabComponent } from '../pinned-tab/pinned-tab.component';
import { TabComponent } from '../tab/tab.component';
import { SearchFieldComponent } from '@abm/controls';
import { TranslateModule } from '@ngx-translate/core';
import { sortByString } from '@utils/sort-array';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-page-tabs',
  standalone: true,
  imports: [
    PinnedTabComponent,
    MenuModule,
    TabComponent,
    AddTabComponent,
    SearchFieldComponent,
    TranslateModule,
    FormsModule,
  ],
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTabsComponent implements AfterViewInit {
  private element = inject(ElementRef).nativeElement;

  private destroyRef = inject(DestroyRef);

  private db = inject(DBService);

  private categoryService = inject(CategoryService);

  @ViewChildren('tab')
  tabList: QueryList<BaseTabComponent> = new QueryList();

  @ViewChild('tabsContainer')
  tabsContainer?: ElementRef<HTMLElement>;

  @Output()
  roundCorners = new EventEmitter<boolean>();

  categoryIndex = computed(() => {
    const categoryId = this.category()?.id;
    let index = 0;
    if (categoryId) {
      index = this.categoryService.categoryList().findIndex((s) => s.id == categoryId);
    }

    return index;
  });

  user = inject(USER);

  category = inject(CATEGORY).value;

  pinnedTabs = computed(() => {
    return this.categoryService.ownCategories().map((c) => CategoryPageTab.create(c, isOwnCategory(this.user().id, c)));
  });

  freeTabs = signal([] as PageTab<CategoryType>[]);

  observedTabs = signal([] as PageTab<CategoryType>[]);

  query = signal('');

  sortedAvailableCategories = computed(() => {
    return this.sortFreeCategory(this.categoryService.availableCategories());
  });

  availableFreeCategories = computed(() =>
    this.sortedAvailableCategories().filter((c) => c.name.toLowerCase().includes(this.query().trim().toLowerCase())),
  );

  showSearchField = computed(() => this.freeTabs().length > 5 || this.query().trim().length);

  constructor() {
    effect(
      async () => {
        const availableCategories = this.categoryService.availableCategories();
        const freeCategories = this.availableFreeCategories() as CategoryType[];

        let data: CategoryId[] = await this.getFreeCateoriesFromDB();
        this.observedTabs.set(
          availableCategories.filter((c) => data.includes(c.id)).map((c) => CategoryPageTab.create(c)),
        );

        this.freeTabs.set(freeCategories.filter((c) => !data.includes(c.id)).map((c) => CategoryPageTab.create(c)));
        {
          const timerId: number = Number(setTimeout(() => this.scrollToActiveTab(timerId), 300));
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngAfterViewInit(): void {
    translateTabListByIndex(this.tabList.filter((t) => t.isPinned).map((e) => e.element));
    translateTabListByIndex(this.tabList.filter((t) => !t.isPinned).map((e) => e.element));

    if (this.tabsContainer) {
      this.tabsContainer.nativeElement.style.marginLeft = `${-this.pinnedTabs().length}px`;
    }
    this.mouseMoveHandler();
  }

  @HostListener('mouseleave')
  mouseleave() {
    this.roundCorners.emit(!this.tabList.first?.active);
  }

  @HostListener('wheel', ['$event'])
  wheel(event: WheelEvent) {
    event.preventDefault();
    const el = this.tabsContainer?.nativeElement;

    if (el) {
      el.scrollLeft += 120 * (event.deltaY / Math.abs(event.deltaY));
    }
  }

  addCategoryToObservedTabs(item: PageTab<CategoryType>) {
    this.observedTabs.update((tabs) => [...tabs, item]);

    this.freeTabs.update((tabs) => tabs.filter((t) => t.data.id !== item.data.id));
    this.updateVisibleTabsInDB(item.data.id, 'add');
  }

  removeCategoryFromObservedTabs(item: PageTab<CategoryType>) {
    this.freeTabs.update((tabs) => this.sortFreeCategory([...tabs, item]) as PageTab<CategoryType>[]);

    this.observedTabs.update((tabs) => tabs.filter((t) => t.data.id !== item.data.id));
    this.updateVisibleTabsInDB(item.data.id, 'remove');
  }

  private async updateVisibleTabsInDB(id: CategoryId, action: 'add' | 'remove') {
    if (this.db.hasConnection()) {
      let data: CategoryId[] = await this.getFreeCateoriesFromDB();
      if (action === 'add') {
        data.push(id);
      } else {
        data = data.filter((categoryId) => categoryId !== id);
      }
      data = await this.db.update('category', 'visible_category_tabs', data);
    } else {
      this.db.getDB('category');
      this.updateVisibleTabsInDB(id, action);
    }
  }

  private async getFreeCateoriesFromDB() {
    return await this.db.getByKey<CategoryId[]>('category', 'visible_category_tabs').catch((e) => {
      if (e instanceof NotFoundRecordError) {
        return this.db.update('category', 'visible_category_tabs', []).catch((e) => console.error(e));
      }

      return e;
    });
  }

  private initRoundCorners() {
    this.roundCorners.emit(!this.tabList.first?.active);
  }

  private mouseMoveHandler() {
    fromEvent<Event>(this.element, 'mousemove')
      .pipe(
        throttleTime(50, animationFrameScheduler),
        tap((event: Event) => {
          if (this.tabList.first?.active) {
            this.roundCorners.emit(false);
            return;
          }

          if (!this.tabList.first?.element.contains(event.target as Node | null)) {
            this.roundCorners.emit(true);
            return;
          }

          this.roundCorners.emit(this.tabList.first?.active);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private scrollToActiveTab(timerId: number) {
    const tablistArr = Array.from(this.tabList);
    const container = this.tabsContainer?.nativeElement;
    const el = tablistArr.find((e) => e.active)?.element;

    if (el && container) {
      container.scroll({
        left: el.offsetLeft - container.offsetLeft - tablistArr.findIndex((e) => e.element == el),
        behavior: 'smooth',
      });
    }

    this.initRoundCorners();

    clearTimeout(timerId);
  }

  private sortFreeCategory(categories: { name: string }[]): { name: string }[] {
    return categories.sort((a, b) => {
      const d1 = a['name'];
      const d2 = b['name'];

      return sortByString(String(d1).toLowerCase(), String(d2).toLowerCase(), 'asc');
    });
  }
}
