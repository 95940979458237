@if (menuItem.url) {
  <lf-link
    class="menu-item"
    [link]="[menuItem.url]"
    [routerLinkActiveOptions]="{ exact: menuItem.url === '/' }">
    @if (menuItem.iconName; as icon) {
      <abm-icon src="assets/icons/menu/icon-{{ icon }}.svg" />
    }
  </lf-link>
} @else {
  <div
    class="menu-item menu-item-without-link"
    [ngClass]="{ 'menu-item-active': isActive() }">
    @if (menuItem.iconName; as icon) {
      <abm-icon src="assets/icons/menu/icon-{{ icon }}.svg" />
    }
  </div>
}
