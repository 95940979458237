import { IconComponent } from '@abm/icon';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { USER } from '@tokens/user.token';
import { SubmenuComponent } from '../submenu-item/submenu.component';
import { LinkComponent } from '@lf/ui';

@Component({
  selector: 'app-user-space-submenu',
  standalone: true,
  imports: [AsyncPipe, IconComponent, RouterLink, TranslateModule, LinkComponent],
  templateUrl: './user-space-submenu.component.html',
  styleUrls: ['./user-space-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSpaceSubmenuComponent extends SubmenuComponent {
  user = inject(USER);
  private auth = inject(AuthService);

  logout() {
    const redirectToDashboard = true;
    this.auth.toLoginPage(redirectToDashboard);
  }
}
